import React from 'react';
import { navigate } from 'gatsby';

import Button from '../../../button';
import Text, { TEXT_COLOR } from '../../../text';
import { Wrapper, ContentWrapper, StyledImage } from './styles';
import Img from '../../../../assets/images/404.png';

const NotFoundSection = () => (
  <Wrapper>
    <ContentWrapper>
      <h1>
        <Text fontSize={120} color={TEXT_COLOR.HIGHLIGHTED}>
          404
        </Text>
      </h1>
      <h2>
        <Text fontSize={40} color={TEXT_COLOR.DARK_GRAY}>
          PAGE NOT FOUND
        </Text>
      </h2>
      <h3>
        <Text fontSize={28} color={TEXT_COLOR.DARK_GRAY}>
          We can’t find the page you were looking for
        </Text>
      </h3>
      <Button
        onClick={() => navigate('/')}
        width={220}
        height={43}
      >
        Go back home
      </Button>
    </ContentWrapper>
    <StyledImage src={Img} />
  </Wrapper>
);

export default NotFoundSection;
