import React from 'react';

import MainLayout from '../layouts/mainLayout';
import NotFoundSection from '../components/pages/404/notFound';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <MainLayout noFooter>
    <SEO title="404: Not found" />
    <NotFoundSection />
  </MainLayout>
);

export default NotFoundPage;
