import styled from '@emotion/styled';

export const Wrapper = styled.section({
  width: '100%',
},
({ theme }) => ({
  minHeight: `calc(100vh - ${theme.sizes.headerHeight}px)`,
  backgroundColor: theme.colors.backgroundMedium,
  '@media (max-width: 480px)': {
    minHeight: `calc(100vh - ${theme.sizes.headerMobileHeight}px)`,
    backgroundColor: theme.colors.background,
  },
}));

export const ContentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: 480,
  paddingTop: 130,
  margin: '0 auto',
  textAlign: 'center',
  '& h1': {
    marginBottom: 25,
  },
  '& h2': {
    marginBottom: 14,
  },
  '& h3': {
    lineHeight: '30px',
    marginBottom: 32,
  },
  '@media (max-width: 480px)': {
    width: '100%',
    padding: '110px 15% 0',
    fontFamily: 'Roboto, sans-serif',
    '& h1': {
      marginBottom: 7,
    },
    '& h1 span': {
      fontSize: 72,
    },
    '& h2': {
      marginBottom: 0,
    },
    '& h3': {
      lineHeight: '22px',
      marginBottom: 18,
    },
    '& h2 span, & h3 span': {
      fontSize: 18,
    },
    '& button': {
      width: 130,
      height: 32,
      fontSize: 12,
    },
  },
},
({ theme }) => ({
  '@media (max-width: 480px)': {
    '& h3 span': {
      color: theme.colors.textGray,
    },
  },
}));

export const StyledImage = styled.img({
  width: 400,
  position: 'absolute',
  bottom: 10,
  left: 0,
  '@media (max-width: 480px)': {
    width: '50%',
    bottom: 0,
  },
});
